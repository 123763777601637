<template>
    <relatorio-form @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></relatorio-form>
</template>

<script>
import RelatorioForm from './RelatorioForm';
import ParticipantesService from './services';

export default {
    components: {
        RelatorioForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: `BrasilMais_Participantes` });
        },

        inserir(relatorioInserido) {
            this.$store.dispatch('addRequest');
            ParticipantesService.inserirRelatorio(relatorioInserido).then((response) => {
                if (response?.success) {
                    if (!response.data.planilhaProcessadaComSucesso) {
                        this.erros = response.data.erros;
                    } else if (response.data.planilhaProcessadaComSucesso && !response.data.todosRegistrosImportados) {
                        this.erros = response.data.erros;
                        this.$store.dispatch('setAtualizar', true);
                    } else {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Relatório',
                            detail: 'Relatório inserido com sucesso',
                            life: 3000,
                        });
                        this.$store.dispatch('setAtualizar', true);
                        this.cancelar();
                    }
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
