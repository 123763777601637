import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_BRASILMAIS_PORT}${process.env.VUE_APP_API_PATH}/brasilmais`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/participantes`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/participantes/${id}`);
    },

    inserirRelatorio(relatorio) {
        const formData = new FormData();
        formData.append('ano', relatorio.ano);
        if (relatorio.arquivo) {
            formData.append('arquivo', relatorio.arquivo);
        }
        return axiosJwt.post(`${api}/participantes/inserir`, formData, formDataType);
    },

    downloadCertificado(participanteId) {
        return axiosJwt.get(`${api}/participantes/${participanteId}/certificado`, {
            responseType: 'blob',
        });
    },

    remover(participanteId) {
        return axiosJwt.delete(`${api}/participantes/${participanteId}`);
    },
};
