<template>
    <painel titulo="Brasil Mais - Inserir Relatório" icone="pi pi-book">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3 lg:col-2">
                <label class="required">Ano</label>
                <Calendar v-model="ano" view="year" dateFormat="yy" :manualInput="false" />
            </div>
            <div class="field col-6">
                <label class="required">Planilha</label>
                <FileUpload
                    :customUpload="true"
                    @uploader="arquivoSelecionado"
                    mode="basic"
                    chooseLabel="Selecionar Relatório"
                    :auto="true"
                    v-if="!arquivo"
                    v-model="arquivo"
                />
                <div v-if="arquivo">
                    <strong>{{ nomeArquivo }}</strong>
                    <i class="pi pi-trash p-error icon-button ml-2" style="fontsize: 1.2rem" @click="limparArquivo()"></i>
                </div>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar'],

    data() {
        return {
            arquivo: null,
            nomeArquivo: null,
            ano: null,
            arquivoAlterado: false,
        };
    },

    validations() {
        return {
            ano: { required },
            arquivo: {
                conteudoOK: () => {
                    return this.conteudoOK;
                },
            },
        };
    },

    methods: {
        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja inserir o relatório?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let relatorioDto = {
                ano: this.$moment(this.ano).format('YYYY'),
                arquivo: this.arquivo,
            };
            this.$emit('salvar', relatorioDto);
        },

        arquivoSelecionado(event) {
            this.arquivo = event.files[0];
            this.nomeArquivo = this.arquivo.name;
        },

        limparArquivo() {
            this.arquivo = null;
            this.arquivoAlterado = true;
        },
    },

    computed: {
        conteudoOK() {
            return this.arquivo !== null;
        },
    },
};
</script>
